.containerDiv {
    width: 210mm;
}

.item {
    display: inline-block;
    vertical-align: top;
}

.big .item {
    padding: 2mm;
    width: 104mm;
}

.small .item {
    padding: 2mm;
    width: 69mm;
    display: inline-flex;
    flex-direction: column;
}

.item>div {
    display: flow-root;
}

.big .item>div {
    font-size: 12px;
}

.small .item>div {
    font-size: 9px;
}

.item h3 {
    text-align: center;
}

.small .item h3 {
    margin-bottom: 0;
}

.categoryLabel {
    text-decoration: underline;
}

.small .psentences {
    display: none;
}

.big .ghsSymbols {
    margin-top: -1em;
    display: flex;
    flex-direction: column;
    float: right;
}

.small .ghsSymbols {
    display: flex;
    justify-content: center;
    order: 2;
}

.big .ghsSymbol {
    height: 26mm;
    width: 26mm;
}

.small .ghsSymbol {
    height: 23mm;
    width: 23mm;
}