.outer {
    padding: 1rem 0;
    max-width: 800px;
}

.padded {
    padding: 0 1rem;
}

.menuDiv {
    float: right;
    margin-bottom: -40px;
    margin-top: -16px;
}

.editPaper {
    padding: 1rem 1rem 0.4rem;
}

.editControl {
    margin-top: 1em;
    display: flex;
    justify-content: flex-end;
}