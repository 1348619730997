.chemicalPaper > * {
    padding: 0 1rem;
}

.blContainer {
    float: right;
    display: flex;
    align-items: center;
}

.blLabel {
    display: inline-block;
    width: 115px;
    text-align: end;
}

.smallLineBreak {
    margin-bottom: 0.4em;
}

.bigLineBreak {
    margin-bottom: 0.7em;
}

.nobr {
    white-space: nowrap;
}