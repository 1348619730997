.label {
    font-weight: bold;
}

.valueNewLine {
    display: block;
    margin-top: 0;
    text-indent: 1.5em;
}

.valueSameLine {
    display: inline-block;
    margin: 0;
}